import React from "react";

// Lazy load with error handling for the import
export const lazyWithErrorHandling = importFunc =>
  React.lazy(() =>
    importFunc().catch(error => {
      console.warn("Failed to load component:", error);
      return {
        default: () => (
          <div className="w-full h-screen flex flex-col items-center justify-center">
            <p className="font-semibold text-lg text-warning">Loading failed</p>
            <p className="max-w-[400px] font-medium text-center dark:text-dark-main-white px-2 my-1">
              Unable to load some resources. Please check your internet
              connection and reload the page.
            </p>
          </div>
        ),
      };
    })
  );
